// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import AuthIndex from '@/components/views/login/auth_index.vue';
import MainIndex from '@/components/views/main/main_index.vue';

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'AuthIndex',
    component: AuthIndex
  },
  {
    path: '/main',
    name: 'MainIndex',
    component: MainIndex
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
