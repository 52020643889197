<template>
  <v-layout class="rounded rounded-md" style="height: 100vh;">
    <MainIndexLeft/>
    <MainIndexTop :style="topbarStyle" />
    <v-main @click="handleMainClick">
      <MainIndexMain :style="mainContentStyle" />
      <CommonSnackBar />
    </v-main>
  </v-layout>
</template>

<script>
import { computed, watch, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useDisplay } from 'vuetify';
import Cookies from 'js-cookie'; 
import MainIndexTop from './main_index_top.vue';
import MainIndexLeft from './main_index_left.vue';
import MainIndexMain from './main_index_main.vue';
import CommonSnackBar from '../common/alam_snackbar.vue';

export default {
  name: 'MainIndex',
  components: {
    MainIndexTop,
    MainIndexLeft,
    MainIndexMain,
    CommonSnackBar,
  },
  setup() {
    const store = useStore();
    const { smAndDown } = useDisplay();
    const drawer = computed(() => store.state.drawer);
    const isMobile = computed(() => store.state.isMobile);
    const router = useRouter();

    const handleMainClick = () => {
      if (isMobile.value) {
        store.commit('setDrawer', false);
      }
    };

    const topbarStyle = ref({});
    const mainContentStyle = ref({});
    const calculateStyles = (drawer) => {
      // 모바일인 경우 스타일을 초기화
      if (smAndDown.value) {
        mainContentStyle.value = {}
        topbarStyle.value = {}
        return
      }

      // 데스크톱인 경우에만 스타일 계산
      const topleftWidth = drawer ? 210 : 0;
      const topleftMargin = drawer ? -46 : 0;

      const mainleftWidth = drawer ? -46 : 0;
      const mainleftMargin = drawer ? -46 : 0;
      
      mainContentStyle.value = {
        width: `calc(100% - ${mainleftWidth}px)`,
        marginLeft: `${mainleftMargin}px`,
        transition: 'all 0.3s ease'  // 부드러운 전환 효과 추가
      }

      topbarStyle.value = {
        width: `calc(100% - ${topleftWidth}px)`,
        marginLeft: `${topleftMargin}px`,
        transition: 'all 0.3s ease'  // 부드러운 전환 효과 추가
      }
    }

    calculateStyles(store.state.drawer)
    

    // bigdrawer 상태 변화 감지
    watch(() => store.state.drawer, (newVal) => {
        if (!smAndDown.value) {  // PC 버전일 때만 스타일 계산
          calculateStyles(newVal)
        }
      }, { immediate: true })

    watch(smAndDown, (newVal) => {
      store.commit('setIsMobile', newVal);
      if (newVal) {
        store.commit('setDrawer', false);
      } else {
        store.commit('setDrawer', true);
      }
      calculateStyles(store.state.drawer)
    }, { immediate: true });

    onMounted(() => {
      // localStorage에서 토큰 확인
      const userToken = Cookies.get('uuid_iv');
      const vft_userToken = Cookies.get('vft_uuid_iv');
      if (!userToken || !vft_userToken) {
        // 토큰이 없으면 로그인 페이지로 리다이렉트
        router.push('/login');
      }
    });

    return {
      drawer, isMobile, mainContentStyle, topbarStyle,
      handleMainClick,
    };
  },
};
</script>
