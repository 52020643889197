import Cookies from 'js-cookie'; 

// user.js
const state = {
  userInfo: null,
};

const mutations = {
  setUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
};

const actions = {
  async fetchUserInfo({ commit }) {
    try {
      const uuid_iv = Cookies.get('uuid_iv');
      const vft_uuid_iv = Cookies.get('vft_uuid_iv');
      const request = `${process.env.VUE_APP_REQUEST_URL}${process.env.VUE_APP_REQUEST_LOCALE}`;
      const response = await fetch(`${request}/menu/user/controller`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ uuid_iv, vft_uuid_iv }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user information');
      }

      const data = await response.json();
      commit('setUserInfo', data.selectData[0]);
      return data.selectData[0];
    } catch (error) {
      console.error('Error fetching user information:', error);
      throw error;
    }
  },
};

const getters = {
  getUserInfo: state => state.userInfo,
  getUserId: state => state.userInfo ? state.userInfo.user_id : null,
  getUserName: state => state.userInfo ? state.userInfo.user_name : null,
  getUserNumber: state => state.userInfo ? state.userInfo.user_number : null,
  getLoginId: state => state.userInfo ? state.userInfo.login_id : null,
  getUserPermission: state => state.userInfo ? state.userInfo.user_permission : null,
  getLastLoginIP: state => state.userInfo ? state.userInfo.last_login_ip : null,
  getLastLoginDate: state => state.userInfo ? state.userInfo.last_login_date : null,
  getDeposit: state => state.userInfo ? state.userInfo.deposit : null,
  getCompanyId: state => state.userInfo ? state.userInfo.company_id : null,
  getCompanyType: state => state.userInfo ? state.userInfo.company_type : null,
  getCompanyName: state => state.userInfo ? state.userInfo.company_name : null,
  getBizNo: state => state.userInfo ? state.userInfo.biz_no : null,
  getBizCEO: state => state.userInfo ? state.userInfo.biz_ceo : null,
  getBizWorkType: state => state.userInfo ? state.userInfo.biz_work_type : null,
  getBizItemType: state => state.userInfo ? state.userInfo.biz_item_type : null,
  getCompanyEmail: state => state.userInfo ? state.userInfo.company_email : null,
  getCompanyZipcode: state => state.userInfo ? state.userInfo.company_zipcode : null,
  getCompanyAddr1: state => state.userInfo ? state.userInfo.company_addr1 : null,
  getCompanyAddr2: state => state.userInfo ? state.userInfo.company_addr2 : null,
  getCompanyTell: state => state.userInfo ? state.userInfo.company_tell : null,
  getLogoImageUrl: state => state.userInfo ? state.userInfo.logo_image_url : null,
  getServiceStatus: state => state.userInfo ? state.userInfo.service_status : null,
  getBcryptUuid: state => state.userInfo ? state.userInfo.bcrypt_uuid : null,
  getVerifyDate: state => state.userInfo ? state.userInfo.verify_date : null,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
