<template>
  <div :style="mobileStyles">
    <!-- dynamicComponent가 null이 아닐 때 해당 컴포넌트를 렌더링 -->
    <component v-if="dynamicComponent" :is="dynamicComponent"></component>
    
    <!-- dynamicComponent가 null일 때 "환영합니다" 문구 렌더링 -->
    <div v-else>
      <FirstPage />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent, computed } from 'vue';
import { useStore } from 'vuex';
import LoadingPage from '@/components/views/common/loading_page.vue';
import WaitingPage from '@/components/views/common/waiting_page.vue';
import FirstPage from '@/components/views/common/first_page.vue';

export default {
  components: {
    FirstPage,
  },
  setup() {
    const store = useStore();
    const isMobile = computed(() => store.state.isMobile);

    const mobileStyles = computed(() => {
      if (!isMobile.value) {
        return {
          backgroundColor: '#F2F2F2',
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
        };
      } else {
        return {
          backgroundColor: '#F2F2F2',
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
        }
      }
    });

    const dynamicComponent = computed(() => {
      const storedConnectMenu = sessionStorage.getItem('ConnectMenu');
      const storedConnectMenuSub = sessionStorage.getItem('ConnectMenuSub');
      const storedLocation = sessionStorage.getItem('location');
      const componentName = store.state.connectMenu || storedConnectMenu;
      const componentNameSub = store.state.connectMenuSub || storedConnectMenuSub;
      const folder = store.getters.getFolder || storedLocation;

      if (!componentName) return null;

      const componentPath = componentNameSub
        ? `${folder}/${componentName}/${componentName}_${componentNameSub}`
        : `${folder}/${componentName}/${componentName}`;

      return defineAsyncComponent({
        loader: () => import(`@/components/views/menu/${componentPath}.vue`),
        loadingComponent: LoadingPage, // 로딩 중에 표시할 컴포넌트
        errorComponent: WaitingPage, // 오류 발생 시 표시할 컴포넌트
        delay: 200, // 로딩 컴포넌트가 표시되기 전까지의 지연 시간 (밀리초)
        timeout: 3000 // 로딩 시간 초과 시간 (밀리초)
      });
    });

    return { dynamicComponent, mobileStyles };
  },
};
</script>
