<template>
    <v-snackbar :color="color" v-model="show" :timeout="timeout">
        {{ message }}
    </v-snackbar>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';

export default {
  setup() {
    const store = useStore();
    
    // Vuex의 상태를 가져오기
    const message = computed(() => store.state.snackbar.message);
    const color = computed(() => store.state.snackbar.color);
    const timeout = computed(() => store.state.snackbar.timeout);
    const show = computed(() => store.state.snackbar.show);
  
    return {
      show,
      message,
      color,
      timeout
    };
  }
}
</script>