<template>
  <div style="
    width: 100%;
    height: 100vh;
    background-color: white;
    display: flex;
    padding: 0px;
  ">
  </div>
</template>

<script>
</script>