// store/snackbar.js

export default {
  state: {
    show: false, // 알림 표시 여부
    message: '', // 알림 메시지
    color: '',   // 알림 색상
    timeout: null, // 알림 표시 시간 (밀리초)
  },
  mutations: {
    setSnackbar(state, { message, color, timeout }) {
      state.message = message;
      state.color = color;
      state.timeout = timeout;
      state.show = true;

      if (state.timeout !== null) {
        setTimeout(() => {
          state.show = false;
        }, state.timeout);
      }
      
    },
  },
  actions: {
    
  },
  getters: {
    
  }
}
