<template>
  <v-navigation-drawer
    v-model="bigdrawer"
    permanent
    rail
    style="background-color: #212121; color: white;"
  >
    <v-list density="compact" nav>
      <v-list-item
        v-for="item in items"
        :key="item.value"
        :prepend-icon="item.icon"
        :class="{ 'selected': selectBigDrawer === item.value }"
        @click="selectItem(item.value)"
      >
      </v-list-item>
    </v-list>
    
    <template v-slot:append v-if="bigdrawer">
      <v-list-item
        prepend-icon="mdi-chevron-double-left"
        style="font-size: 14px; color: #E7E7E7; color: white;"
        @click="bigdrawerToggle"
      >
      </v-list-item>
    </template>
  </v-navigation-drawer>
  
  <v-navigation-drawer
    v-model="drawer"
    style="background-color: #2D353C; color: white; width: 210px;"
    permanent
  >
    <component :is="drawerComponent" />

    <template v-slot:append v-if="!bigdrawer && bigdrawerStatus">
      <v-list-item
        prepend-icon="mdi-chevron-double-right"
        style="font-size: 14px; color: #E7E7E7; background-color: #212121; color: white;"
        @click="bigdrawerToggle"
      >
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { ref, onMounted, computed } from 'vue';
import { useStore } from 'vuex';
import { fetchGetData } from '../JSfunction/usedFunction.js';
import Cookies from 'js-cookie'; 
import DashBoard from '../drawer_class/drawer_dashboard.vue';
import FileBoard from '../drawer_class/drawer_fileboard.vue';

export default {
  components: {
    DashBoard,
    FileBoard,
  },
  setup() {
    const store = useStore();
    const userId = Cookies.get('uuid_iv');
    const apiPath = '/admin/login/select/drawerlist';
    const drawer = computed(() => store.state.drawer);
    const bigdrawer = computed(() => store.state.bigdrawer);
    const bigdrawerStatus = ref(true);
    const selectBigDrawer = ref(store.state.selectedBigDrawer);
    const items = ref([]);

    const drawerToggle = () => {
      store.commit('setDrawer', !store.state.drawer);
    };
    const bigdrawerToggle = () => {
      store.commit('setBigDrawer', !store.state.bigdrawer);
    };
    const selectItem = (value) => {
      store.commit('setselectedBigDrawer', value);
      selectBigDrawer.value = value;
    };

    const drawerComponent = computed(() => {
      switch (selectBigDrawer.value) {
        case 'dashboard':
          return DashBoard;
        case 'fileboard':
          return FileBoard;
        default:
          return null;
      }
    });

    const logoImageUrl = ref(null);
    const companyName = ref(null);
    const fetchLocalStorageData = () => {
      logoImageUrl.value = localStorage.getItem('logo-image-url') || 'https://cdn.samjinnetworks.com/images/web/company_image/1.png';
      companyName.value = localStorage.getItem('company-name') || '삼진네트웍스';
    };

    onMounted(() => {
      fetchLocalStorageData();
      
      fetchGetData(apiPath, 'uuid_iv='+userId)
      .then(data => {
        items.value = data.map(item => ({
            value: item.drawer_value,
            icon: item.drawer_icon,
          }));
        })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
      //bigDrawer 테이블에 데이터가 없는 경우 보여주지않는다
        if(items.value.length === 0) {
          bigdrawerStatus.value = false;
        }
      });

    });

    return {
      logoImageUrl, companyName, drawer, bigdrawer, selectBigDrawer, items, drawerComponent, userId, apiPath,
      fetchLocalStorageData, drawerToggle, bigdrawerToggle, selectItem, bigdrawerStatus,
    };
  },
};
</script>

<style>
.selected {
  background-color: rgba(255, 255, 255, 0.4);
  color: black;
}

/* 스크롤바 스타일 */
::-webkit-scrollbar {
    width: 6px; /* 스크롤바 너비 */
    height: 6px; /* 수직 스크롤바의 높이 */
}

/* 스크롤바의 트랙 */
::-webkit-scrollbar-track {
    background: transparent; /* 스크롤바 트랙의 배경색 */
}

/* 스크롤바의 핸들 */
::-webkit-scrollbar-thumb {
    background: #888; /* 스크롤바 핸들의 배경색 */
    border-radius: 3px; /* 스크롤바 핸들의 모서리 반경 */
}

/* 마우스 호버 시 스크롤바 핸들 */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* 마우스 호버 시 스크롤바 핸들의 배경색 */
}

</style>
