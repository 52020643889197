import { createStore } from 'vuex';
import userModule from './user';
import snackbarModule from './snackbar'

export default createStore({
  modules: {
    user: userModule,
    snackbar: snackbarModule,
  },
  state() {
    return {
      connectMenu: null, // connectMenu의 상태를 저장
      connectMenuSub: null, // connectMenuSub의 상태를 저장
      menuName: sessionStorage.getItem('menu_name'),
      editDataId: null,
      folder: null,
      drawer: true,
      bigdrawer: JSON.parse(localStorage.getItem('bigdrawer')) || false,
      selectedBigDrawer : localStorage.getItem('selectedbigdrawer') || 'dashboard',
      isMobile: false,
    };
  },
  mutations: {
    setConnectMenu(state, payload) {
      state.connectMenu = payload; // connectMenu 상태 업데이트
      state.editDataId = null; // connectMenu가 변경되면 editDataId를 null로 설정
    },
    setConnectMenuSub(state, payload) {
      state.connectMenuSub = payload; // connectMenuSub 상태 업데이트
      state.editDataId = payload === '' ? null : state.editDataId; // connectMenu가 변경되면 editDataId를 null로 설정
    },
    setMenuName(state, menuName) {
      state.menuName = menuName;
    },
    setEditDataId(state, id) { // 추가: editDataId 뮤테이션
      state.editDataId = id;
    },
    setFolder(state, payload) {
      state.folder = payload; // folder 상태 업데이트
    },
    setDrawer(state, value) {
      state.drawer = value;
    },
    setBigDrawer(state, value) {
      state.bigdrawer = value;
      localStorage.setItem('bigdrawer', value);
    },
    setselectedBigDrawer(state, value) {
      state.selectedBigDrawer = value;
      localStorage.setItem('selectedbigdrawer', value);
    },
    setIsMobile(state, value) {
      state.isMobile = value;
    },
  },
  actions: {
    setConnectMenu({ commit }, payload) {
      commit('setConnectMenu', payload); // connectMenu 상태 변경을 위한 mutation 호출
      sessionStorage.setItem('ConnectMenu', payload);
    },
    setConnectMenuSub({ commit }, payload) {
      commit('setConnectMenuSub', payload); // connectMenuSub 상태 변경을 위한 mutation 호출
      sessionStorage.setItem('ConnectMenuSub', payload);
    },
    setMenuName({commit}, menuName) {
      commit('setMenuName', menuName);
    },
    setEditDataId({ commit }, id) { // 추가: editDataId를 설정하기 위한 액션
      commit('setEditDataId', id);
    },
    setFolder({ commit }, payload) {
      commit('setFolder', payload); // folder 상태 변경을 위한 mutation 호출
      sessionStorage.setItem('location', payload);
    },
    setDrawer({ commit }, value) {
      commit('setDrawer', value);
    },
    setBigDrawer({ commit }, value) {
      commit('setBigDrawer', value);
      localStorage.setItem('bigdrawer', value);
    },
    setselectedBigDrawer({ commit }, value) {
      commit('setselectedBigDrawer', value);
      localStorage.setItem('selectedbigdrawer', value);
    },
    setIsMobile({ commit }, value) {
      commit('setIsMobile', value); // 모바일 상태 업데이트를 위한 액션
    },
  },
  getters: {
    getConnectMenu: state => state.connectMenu, // connectMenu 상태를 반환
    getConnectMenuSub: state => state.connectMenuSub, // connectMenuSub 상태를 반환
    getEditDataId: state => state.editDataId,
    getFolder: state => state.folder,
    getDrawer: state => state.drawer,
    getBigDrawer: state => state.bigdrawer,
    getIsMobile: state => state.isMobile, // 모바일 상태를 반환
  }
});
