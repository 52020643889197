<template>
  <v-list density="compact">
    <v-list-subheader color="white">
      <v-icon icon="mdi-chevron-down"></v-icon>
      파일관리자
    </v-list-subheader>
    <v-list-item
      v-for="(item, i) in items"
      :key="i"
      :value="item"
    >
      <template v-slot:prepend>
        <v-icon :icon="item.icon"></v-icon>
      </template>

      <v-list-item-title v-text="item.text"></v-list-item-title>
    </v-list-item>

    <v-list-subheader color="white">
      <v-icon icon="mdi-delete"></v-icon>
      휴지통
    </v-list-subheader>
  </v-list>

</template>
<script>
import { onMounted, computed } from 'vue';
import { useStore } from 'vuex';

  export default {
    setup() {
      const store = useStore();
      const isMobile = computed(() => store.state.isMobile);
      const items = [
        { text: 'Folder1', icon: 'mdi-chevron-right' },
        { text: 'Folder2', icon: '' },
        { text: 'Folder3', icon: 'mdi-chevron-right' },
      ]

      const selectSubMenu = (connect_menu, menu_name, folder) => {
        store.dispatch('setConnectMenu', connect_menu);
        store.dispatch('setMenuName', menu_name);
        store.dispatch('setFolder', folder);
        store.dispatch('setConnectMenuSub', '');

        //메뉴명을 세션스토리지에 저장
        sessionStorage.setItem('menu_name', menu_name);

        if (isMobile.value) {
          store.commit('setDrawer', !store.state.drawer);
        }
      };

      onMounted(() => {
        selectSubMenu('FileAllDataManagement', '파일관리자', 'FileManage')
      })

      return {
        items, isMobile,
        selectSubMenu,
      }
    },
  }
</script>