<template>
  <v-app-bar
    density="compact"
    style="
      background-color: #FFFFFF;
      color: #000000;
      border-bottom: 1px solid #E7E7E7;
    "
    :elevation="0"
  >
    <template v-slot:prepend>
      <v-app-bar-nav-icon
        @click="drawerToggle"
      ></v-app-bar-nav-icon>
    </template>

    <v-app-bar-title
      style="
        font-size: 16px;
      "
    >
      {{menuName}}
    </v-app-bar-title>

    <template v-slot:append>
      <!-- <v-btn icon="mdi-pencil">
        <v-icon
          @click="handleUtilapp('Memo')"
        >mdi-pencil</v-icon>
      </v-btn>
      <v-btn icon="mdi-check">
        <v-icon
          @click="handleUtilapp('Todolist')"
        >mdi-check</v-icon>
      </v-btn>
      <hr class="v-divider v-divider--inset v-divider--vertical v-theme--light mx-2 my-auto ms-3 me-2" aria-orientation="vertical" role="separator" style="height: 19px;"> -->
      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn
            icon="mdi mdi-cog"
            v-bind="props"
          ></v-btn>
        </template>
        <v-card min-width="150">
          <v-divider></v-divider> <!-- Separator -->
          <v-list-item
            v-for="(menu, index) in settingMenu"
            :key="index"
            @click="handleItemClick(menu)"
            :active-class="'active-item'"
          >
            <v-list-item-title>{{ menu }}</v-list-item-title>
          </v-list-item>
        </v-card>
      </v-menu>
    </template>


  </v-app-bar>
</template>
<script>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Cookies from 'js-cookie';

export default {
  setup() {
    const store = useStore();
    const router = useRouter();
    const settingMenu = ref(['계정', '로그아웃']);
    const drawer = computed(() => store.state.drawer);
    const drawerToggle = () => {
      store.commit('setDrawer', !store.state.drawer);
    };
    const menuName = computed(() => store.state.menuName);

    const handleUtilapp = (title) => {
      if (title === 'Memo') {
        store.dispatch('setMenuName', '메모장');
        store.dispatch('setFolder', 'Utils');
        store.dispatch('setConnectMenu', 'MemoUtilapp');
      } else if (title === 'Todolist') {
        store.dispatch('setMenuName', 'Todolist');
        store.dispatch('setFolder', 'Utils');
        store.dispatch('setConnectMenu', 'TodolistUtilapp');
      }
    }

    const handleItemClick = (title) => {
        if (title === '로그아웃') {
          localStorage.clear();
          sessionStorage.clear();

          const cookies = Cookies.get();
          for (const cookie in cookies) {
            Cookies.remove(cookie);
          }
          router.push({ name: 'AuthIndex' });
        } else if (title === '계정') {
          store.dispatch('setFolder', 'Settings');
          store.dispatch('setConnectMenu', 'AccountManagement');
        }
      };

    return {
      settingMenu, drawer, menuName,
      handleItemClick, drawerToggle, handleUtilapp,
    };
  }  
}
</script>