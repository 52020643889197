<template>
<v-list>
  <v-list-item density="compact">
    <p style="
      font-size: 16px; 
      color: #FFFFFF; 
      font-style: italic;
      cursor: pointer;" 
      @click="handleHome"
      >
      SAMJIN
      <br>
      NETWORKS
    </p>
  </v-list-item>
      
  <v-divider></v-divider>
      
  <v-list-group
      v-for="(largeClassItem, index) in largeClass"
      :key="index"
      v-model="openMenus[largeClassItem.menu_code]"
  >
      <template v-slot:activator="{ props }">
      <v-list-item
          v-bind="props"
          :value="largeClassItem.menu_code"
          :append-icon="openMenus[largeClassItem.menu_code] ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          @click="toggleMenu(largeClassItem.menu_code)"
          style="
          font-size: 14px;
          color: #E7E7E7;
          "
      >
      {{ largeClassItem.menu_name }}
      </v-list-item>
      <v-list-item
          v-for="(middleClassItem, index) in filteredMiddleClass(largeClassItem.menu_code)"
          :key="index"
          append-icon=""
          @click="selectSubMenu(middleClassItem.folder,middleClassItem.connect_menu,middleClassItem.menu_name)"
          style="
          font-size: 14px;
          color: #E7E7E7;
          "
      >
      {{middleClassItem.menu_name}}
      </v-list-item>
      </template>
  </v-list-group>
</v-list>
</template>
    
  <script>
    import { ref, onMounted, computed } from 'vue';
    import { useStore } from 'vuex';
    import Cookies from 'js-cookie';
    import axios from 'axios';
    
    export default {
      setup() {
        const store = useStore();
        const isMobile = computed(() => store.state.isMobile);
        const drawer = computed(() => store.state.drawer);
        const bigdrawer = computed(() => store.state.bigdrawer);
        const drawerToggle = () => {
          store.commit('setDrawer', !store.state.drawer);
        };
        const bigdrawerToggle = () => {
          store.commit('setBigDrawer', !store.state.bigdrawer);
        };
        const largeClass = ref([]);
        const middleClass = ref([]);
        const openMenus = ref(new Set());
        const companyName = ref(null);
        const fetchLocalStorageData = () => {
          companyName.value = localStorage.getItem('company-name') || 'Default Company Name';
        };
    
        const fetchMenus = async () => {
          try {
            const request = `${process.env.VUE_APP_REQUEST_URL}${process.env.VUE_APP_REQUEST_LOCALE}`;
            const uuid_iv = Cookies.get('uuid_iv');
            const response = await axios.get(`${request}/admin/login/select/menulist?uuid_iv=${uuid_iv}`);
            response.data.category.forEach(item => {
              if (item.menu_depth === "0") {
                largeClass.value.push(item);
              } else if (item.menu_depth === "1") {
                middleClass.value.push(item);
              }
            });
          } catch (error) {
            console.error('메뉴 데이터 가져오기 실패:', error);
          }
        };

        const handleHome = () => {
          store.dispatch('setMenuName', '');
          store.dispatch('setFolder', '');
          store.dispatch('setConnectMenu', '');
        }
  
        
        const toggleMenu = (menu_code) => {
          if (openMenus.value.has(menu_code)) {
            openMenus.value.delete(menu_code);
          } else {
            openMenus.value.add(menu_code);
          }
          openMenus.value[menu_code] = !openMenus.value[menu_code];
        };
    
        const filteredMiddleClass = (menu_code) => {
          if (openMenus.value.has(menu_code)) {
            return middleClass.value.filter(item => item.menu_code === menu_code);
          }
          return [];
        };
  
      const selectSubMenu = (folder, connect_menu, menu_name) => {
  
  
        store.dispatch('setConnectMenu', connect_menu);
        store.dispatch('setMenuName', menu_name);
        store.dispatch('setFolder', folder);
        store.dispatch('setConnectMenuSub', '');
  
        //메뉴명을 세션스토리지에 저장
        sessionStorage.setItem('menu_name', menu_name);
  
        if (isMobile.value) {
          store.commit('setDrawer', !store.state.drawer);
        }
      };
    
        onMounted(() => {
          fetchMenus();
          fetchLocalStorageData();
        });
    
        return {
          largeClass, middleClass, openMenus, companyName, drawer, bigdrawer, handleHome,
          toggleMenu, filteredMiddleClass, fetchLocalStorageData, selectSubMenu, drawerToggle, bigdrawerToggle,
        };
      },
    };
  </script>
  