<template>
  <div id="app">
    <div class="auth-container">
      <h1>ADMIN LOGIN</h1>
      <p class="subtitle">Business Management System</p>
      <form @submit.prevent="onSubmit">
        <div class="input-wrapper">
          <input v-model="username" placeholder="ID" required>
        </div>
        <div class="input-wrapper">
          <input type="password" v-model="password" placeholder="PASSWORD" required>
        </div>
        <div class="submit-wrapper">
          <button type="submit">로그인</button>
        </div>
        <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
      </form>
    </div>
  </div>

</template>

<script>
import { ref } from 'vue';
import axios from 'axios';
import Cookies from 'js-cookie';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default {
  name: 'AuthIndex',
  setup() {
    const store = useStore();
    const router = useRouter();
    const username = ref('');
    const password = ref('');
    const errorMessage = ref('');
    

    const onSubmit = async () => {
      try {

        //아이디를 소문자로 변경하고 스페이스바 치환
        const formattedUsername = username.value.toLowerCase().replace(/\s+/g, '');

        const request = `${process.env.VUE_APP_REQUEST_URL}${process.env.VUE_APP_REQUEST_LOCALE}`;
        const workClassification = process.env.VUE_APP_WORK_CLASSIFICATION === 'server';
        const response = await axios.post(`${request}/thirteen/encryption/select/user`, {
          login_id: formattedUsername,
          login_password: password.value
        });

        const currentTime = new Date();
        const expirationTime = new Date(currentTime.getTime() + 24 * 60 * 60 * 1000);

        Cookies.set('vft_uuid_iv', response.data.bcrypt_uuid, { expires: expirationTime, secure: workClassification, sameSite: 'Strict' });
        Cookies.set('uuid_iv', response.data.uuid, { expires: expirationTime, secure: workClassification, sameSite: 'Strict' });

        localStorage.setItem('company-name', response.data.company_name);
        localStorage.setItem('logo-image-url', response.data.logo_image_url);

        // Redirect to the dashboard or main page
        router.push({ name: 'MainIndex' });

        store.commit('setSnackbar', {
          message: `${Date()} 로그인하였습니다`,
          color: '',
          timeout: 4000
        });

      } catch (error) {
        errorMessage.value = '아이디와 비밀번호를 확인하세요';
        console.error('로그인 오류:', error);
      }
    };

    return {
      username,
      password,
      errorMessage,
      onSubmit
    };
  },
};
</script>

<style scoped>
#app {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
  background-color: #212121;
}

.auth-container {
  background-color: #EBECEF;
  border-radius: 20px;
  width: 100%;
  max-width: 400px;
  height: 80vh;
  max-height: 550px;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .auth-container {
    height: 80%;
  }
}

h1 {
  margin-bottom: 0.5em;
}

.subtitle {
  margin-bottom: 2em;
  color: #333;
  font-size: 0.9em;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.input-wrapper {
  margin-bottom: 1em;
  width: 100%;
}

.input-wrapper input {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #000000;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 1em;
  background-color: #FFFFFF;
}

.submit-wrapper {
  width: 100%;
}

.submit-wrapper button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 20px;
  background-color: black;
  color: white;
  font-size: 1em;
  cursor: pointer;
}

button:hover {
  background-color: #333;
}
</style>
